//PENSER A TRADUIRE DANS LES FICHIERS I18N

export const ERROR_DEFAULT = 'defaultError'
export const ERROR_RESOLVE_ACCESS_DICTIONNARY = 'resolveAccessDictionary'
export const ERROR_LOADING_DATA_GRID_PROPERTIES = 'dataGridProperties'

export const ERROR_FORMULA = "formula"
export const ERROR_VARIABLE = "variable"
export const ERROR_METRIC = "metric"
export const ERROR_DIMENSION_MAPPING = "dimensionMapping"
export const ERROR_PREDIC_TEMPLATE = "predicTemplate"
export const ERROR_PREDIC_TEMPLATE_DUPLICATE = "predicTemplateDuplicate"
export const ERROR_PREDIC_TEMPLATE_CHECKING = "predicTemplateChecking"
export const ERROR_PREDIC_TEMPLATE_TRANSACTION_VERSIONING_INVOLVED = "predicTemplateTransactionVersioningInvolved"
export const ERROR_PREDIC_TEMPLATE_PATCH_LINK_SECTIONS = "predicTemplatePatchLinkSections"
export const ERROR_PREDIC_SECTIONS_CHECKING_PARTIAL = "predicSectionsCheckingPartial"
export const ERROR_PREDIC_SECTIONS_DISABLE_CHECKING = "predicSectionsDisableChecking"
export const ERROR_PREDIC_SECTIONS_ACTIVATE_CHECKING = "predicSectionsActivateChecking"
export const ERROR_PREDIC_TEMPLATE_SECTION = "predicTemplateSection"
export const ERROR_PREDIC_TEMPLATE_SECTION_PATCH_LINE_DATAS = "predicTemplateSectionPatchLineDatas"
export const ERROR_PREDIC_TEMPLATE_SECTION_WFL = "predicTemplateSectionWorkflow"
export const ERROR_PREDIC_TEMPLATE_SECTION_HISTORIC = "predicTemplateSectionHistoric"
export const ERROR_PREDIC_TEMPLATE_SECTION_DUPLICATE = "predicTemplateSectionDuplicate"
export const ERROR_PREDIC_TEMPLATE_SECTION_DELETE = "predicTemplateSectionDelete"
export const ERROR_PREDIC_TEMPLATE_SECTION_DUPLICATE_DETAIL = "predicTemplateSectionDuplicateDetail"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE = "predicTemplateSectionLine"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE_FROM_LST_UUID = "predicTemplateSectionLineFromLstUuid"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE_REMINDERS = "predicTemplateSectionLineReminders"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE_DEPENDENCIES = "predicTemplateSectionLineDependencies"
export const ERROR_PREDIC_TEMPLATE_SECTION_GLOBAL_UPDATE = "predicTemplateSectionGlobalUpdate"
export const ERROR_PREDIC_DEPLOYED = "predicDeployed"
export const ERROR_PREDIC_WFL_COUNTER = "predicWflCounter"
export const ERROR_PREDIC_DEPLOYED_CHECK_DRIVER_BASE_VALIDITY = "predicDeployedCheckDriverBaseValidity"
export const ERROR_PREDIC_DEPLOYED_CALCUL_DRIVER_BASE = "predicDeployedErrorCalculDriverBase"
export const ERROR_PREDIC_DEPLOYED_SECTION_HISTORIC = "predicDeployedSectionHistoric"
export const ERROR_PREFERENCE_COMMON = "preferenceCommon"
export const ERROR_FORM_USER = "formUser"
export const ERROR_COMPANY = "company"
export const ERROR_CURRENCY_CONVERSION = "currencyConversion"
export const ERROR_ENTITY = "entity"
export const ERROR_FILE_IMPORT = "fileImport"
export const ERROR_TRANSACTION_VERSIONING = "transactionVersioning"
export const ERROR_NARRATIVE_CATEGORY = "narrativeCategory"
export const ERROR_PREDIC_TAG_LINE = "predicTagLine"
export const ERROR_FORM_DATASETGROUP = "formDatasetGroup"
export const ERROR_LICENSE = "license"
export const ERROR_LOADING_CONTEXT_FOREX_OPTIONS = 'contextForexOptions'
export const ERROR_RESOLVE_ENTITY_LIST = 'resolveEntityList'
export const ERROR_RESOLVE_FORMATTER_NUMBER = 'resolveFormatterNumber'
export const ERROR_RESOLVE_DATASET_GROUP = 'resolveDatasetGroup'
export const ERROR_RESOLVE_USER_DATASETS = 'resolveUserDatasets'
export const ERROR_RESOLVE_USER_GRID = 'resolveUserGrid'
export const ERROR_TRANSACTION_DETAIL = 'resolveTransactionDetailsDimensions'
export const ERROR_NUMBER_FORMATTER_BLOC_LINES = 'resolveNumberFomatterBlocLines';



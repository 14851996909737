
import messages_fr_FR from './messages_fr_FR.json';
import messages_en_US from './messages_en_US.json';
import messages_tr_TR from './messages_tr_TR.json';

import frMessages from "devextreme/localization/messages/fr.json";
import enMessages from "devextreme/localization/messages/en.json";
import trMessages from "devextreme/localization/messages/tr.json";

import { /*locale as dxLocale,*/ loadMessages as dxLoadMessages } from "devextreme/localization";

import IntlMessageFormat from 'intl-messageformat';
import moment from 'moment';
import 'moment/locale/fr'


export const getBrowserLanguage = () => {

    return window.navigator.userLanguage || window.navigator.language
}



export const initMessages = () => {
    dxLoadMessages(trMessages);
    dxLoadMessages(frMessages);
    dxLoadMessages(enMessages);
}

export const getMessages = (mLang) => {

    if (!mLang) {
        dxLoadMessages(frMessages);
        return messages_fr_FR;
    }

    const language = localeWithoutRegionCode(mLang);  // language without region code

    switch (language) {
        case "fr":
            dxLoadMessages(frMessages);
            return messages_fr_FR;
        case "tr":
            dxLoadMessages(trMessages);
            return messages_tr_TR;

        default:
            dxLoadMessages(enMessages);
            return messages_en_US;
    }

}

/**
 * 
 * @param {*} languageCode 
 * @returns language without region code 'fr_FR' -> 'fr'
 * 
 * 
 */
export const localeWithoutRegionCode = (languageCode) => {
    return languageCode.split(/[-_]/)[0]
}

export const i18nWithLanguage = (key, values, language) => {

    if (!!language) {
        const msgFromKey = getMessages(language)[key]
        const msg = new IntlMessageFormat(msgFromKey, language);
        const output = msg.format(values)
        return output
    } else {
        return '...'
    }

}

// attention aux imports de langue moment
// import 'moment/locale/fr' ...
const formatDateWithMoment = (date, language, stringFormat) => {
    if (!!date && !!language) {
        let mdate = moment(date)
        mdate.locale(localeWithoutRegionCode(language))
        return mdate.format(stringFormat)
    } else {
        return '...'
    }

}

// format MMM D, yyyy
// => juil. 2, 2021
export const getMMMdyyyyWithLanguage = (date, language) => {
    return formatDateWithMoment(date, language, 'MMM D, yyyy')
}

export const getDddWithLanguage = (date, language) => {
    return formatDateWithMoment(date, language, 'ddd')
}

export const getMMMyyWithLanguage = (date, language) => {
    return formatDateWithMoment(date, language, 'MMM YY')
}

export const getJJMMAAAAWithLanguage = (date, language) => {
    if (!!date && !!language) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
        return new Intl.DateTimeFormat(language, options).format(getDateFromAnyFormat(date))
    } else {
        return '...'
    }
}

export const getDayDateAndMonthAndYearWithLanguage = (date, language) => {
    if (!!date && !!language) {
        const options = { dateStyle: 'full' }
        return new Intl.DateTimeFormat(language, options).format(date)
    } else {
        return '...'
    }
}

export const getMonthAndYearWithLanguage = (date, language) => {
    if (!!date && !!language) {
        const options = { year: "numeric", month: "long" }
        return new Intl.DateTimeFormat(language, options).format(date)
    } else {
        return '...'
    }
}


export const getHHMMWithLanguage = (date, language) => {
    if (!!date && !!language) {
        const options = { hour: 'numeric', minute: 'numeric' }
        return new Intl.DateTimeFormat(language, options).format(getDateFromAnyFormat(date))
    } else {
        return '...'
    }
}

export const getJJMMAAAA_HHMMWithLanguage = (date, language) => {
    if (!!date && !!language) {
        const options = {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: 'numeric', minute: 'numeric'
        }
        return new Intl.DateTimeFormat(language, options).format(getDateFromAnyFormat(date))
    } else {
        return '...'
    }
}

export const getYYYYWithLanguage = (date, language) => {
    if (!!date && !!language) {
        const options = { year: 'numeric' }
        return new Intl.DateTimeFormat(language, options).format(date)
    } else {
        return '...'
    }
}


/**
 * 
 * @param {
 * } date 
 * @param {*} language inutilisé pour le moment
 * @returns une string : 'morning','afternoon' ou 'evening'
 */

export const getMomentumWithLanguage = (date, language) => {

    const momentum = [
        { "start": 0, "end": 4, "lib": "evening" },
        { "start": 5, "end": 11, "lib": "morning" },
        { "start": 12, "end": 16, "lib": "afternoon" },
        { "start": 17, "end": 23, "lib": "evening" }]

    const h = date.getHours();

    const moment = momentum.find(m => m.start <= h && m.end >= h)

    return moment?.lib
}

export const getDateFromAnyFormat = (date) => {
    switch (date.constructor) {
        case Number:
        case String:
            return new Date(date);
        case Date:
            return date;
        default :
            return 'getDateFromAnyFormat : unknown format'
    }
}

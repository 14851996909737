//import Keycloak from 'keycloak-js'
import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getEndPointAccessDictionary,
  getEndPointPreferenceRetreive,
  PREF_TYPE_FORMATTAGE_NUMBER,
} from "../api/endPoints";
import mapperFunctionalModuleTree from "../components/analysis/mappers/mapperFunctionalModuleTree";
//import mapperOperationalModuleTree from '../components/analysis/mappers/mapperOperationalModuleTree'
import { FMC_ANALYTICS } from "../components/assets/const/FunctionalModuleCategories";
import {
  notifyError,
  notifySuccess,
} from "../components/commons/utils/notification/notification";
import { ShorterFormatNone } from "../components/commons/form/formatter/display/ShorterFormatDisplay";
import {
  SeparatorComma,
  SeparatorPoint,
} from "../components/commons/form/formatter/display/Separator";
import { FormatNegatifMinus } from "../components/commons/form/formatter/display/FormatNegatifDisplay";
import { PositionSymbolNone } from "../components/commons/form/formatter/display/PositionSymbolDisplay";
import { useContextLog } from "./ContextLog";
import useStateWithCallback from "use-state-with-callback";
import { ERROR_RESOLVE_ACCESS_DICTIONNARY } from "../components/assets/const/ErrorCode";
import { ERROR_RESOLVE_FORMATTER_NUMBER } from "../components/assets/const/ErrorCode";
import { ROLE_COMPANY_ADMIN, ROLE_COMPANY_USER, ROLE_INHOVATE_ADMIN, ROLE_INHOVATE_USER } from "./ListRolesUsers"
import { FormatZeroZero } from "../components/commons/form/formatter/display/FormatZeroDisplay";
import { TypeDescriptiveRight_RO } from "../components/analysis/dynamic_dashboard/users/TypeDescriptiveRight";


const ContextUser = createContext({});
const useContextUser = () => useContext(ContextUser);

function UseContextUserProvider(props) {

  const urlApi = props.urlApi;
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState();
  const isUserInhovateAdmin = user?.role === ROLE_INHOVATE_ADMIN?.name
  const isUserInhovateUser = user?.role === ROLE_INHOVATE_USER?.name
  const isUserCompanyAdmin = user?.role === ROLE_COMPANY_ADMIN?.name
  const isUserCompanyUser = user?.role === ROLE_COMPANY_USER?.name

  const [functionalModuleTreeList, setFunctionalModuleTreeList] =
    useStateWithCallback([], () => {
      setNeedResolveAccessDictionary(false);
    });
  //  const [operationalModuleTreeList, setOperationalModuleTreeList] = useState()
  const functionalModuleCategory = FMC_ANALYTICS; // mettre en state pour la V2
  const [needResolveAccessDictionary, setNeedResolveAccessDictionary] =
    useState(true);

  // formatter number
  const [userFormatterNumber, setUserFormatterNumber] = useState();
  const [needResolveUserFormatterNumber, setNeedResolveUserFormatterNumber] = useState();
  const [readOnlyFinalizerOrApprobator, setReadOnlyFinalizerOrApprobator] = useState(true)
  const [keycloak] = useState(props.keycloak);
  const { addErrorLog } = useContextLog();

  const getRequestHeaders = useCallback(() => {
    const headers = {
      headers: { Authorization: " Bearer " + keycloak?.token },
    };
    return headers;
  }, [keycloak?.token]);

  /**
   * Load API to resolve Access Dictionary
   */
  const resolveAccessDictionary = useCallback(async () => {
    
    axios
      .get(getEndPointAccessDictionary(urlApi), getRequestHeaders())
      .then((response) => {
        if (!!response?.erreur) throw response.erreur;
        setUser(response.data.user);
        setUserCompany(response.data.company);
        setFunctionalModuleTreeList(mapperFunctionalModuleTree(response));
        notifySuccess("User pref loaded");
        setNeedResolveUserFormatterNumber(true);
      })
      .catch(function (err) {
        addErrorLog(err, ERROR_RESOLVE_ACCESS_DICTIONNARY);
        setFunctionalModuleTreeList([]);
      });
  }, [addErrorLog, getRequestHeaders, setFunctionalModuleTreeList, urlApi]);

  useEffect(() => {
    if (needResolveAccessDictionary) resolveAccessDictionary();
  }, [user, resolveAccessDictionary, keycloak, needResolveAccessDictionary]);


  const [resolveUserFormatterNumberIsCrashed, setResolveUserFormatterNumberIsCrashed] = useState(false);

  const resolveUserFormatterNumber = useCallback(async () => {
    try {

      const defaultFormatter = {
        positionSymbol: PositionSymbolNone,
        formatNegatif: FormatNegatifMinus,
        precision: 2,
        separatorThousand: SeparatorComma,
        separatorDecimal: SeparatorPoint,
        shorterFormat: ShorterFormatNone,
        formatZero: FormatZeroZero,
        isIndianNotation: false,
      };
      const nameScreen = undefined; // pour le formatage, le screen est non défini
      const nameModule = undefined; // pour le formatage, le screen est non défini

      const endPoint = getEndPointPreferenceRetreive(
        urlApi,
        nameScreen,
        nameModule,
        PREF_TYPE_FORMATTAGE_NUMBER
      );
      axios
        .get(endPoint, getRequestHeaders())
        .then((response) => {
          if (!!response?.erreur) throw response.erreur;

          if (!!response?.data) {
            setUserFormatterNumber(response.data);
          } else {
            setUserFormatterNumber(defaultFormatter);
          }
          setNeedResolveUserFormatterNumber(false);
        })
        .catch(function (err) {
          addErrorLog(err, ERROR_RESOLVE_FORMATTER_NUMBER);
          setUserFormatterNumber(defaultFormatter);
          setResolveUserFormatterNumberIsCrashed(true);
        });
    } catch (e) {
      notifyError(e);
    }
  }, [addErrorLog, getRequestHeaders, urlApi]);

  useEffect(() => {
    if (!!user) setNeedResolveUserFormatterNumber(true);
  }, [user, resolveUserFormatterNumber]);

  useEffect(() => {
    if (needResolveUserFormatterNumber && !resolveUserFormatterNumberIsCrashed) resolveUserFormatterNumber();
  }, [needResolveUserFormatterNumber, resolveUserFormatterNumber, resolveUserFormatterNumberIsCrashed]);



  const signOut = () => {
    console.log("SIGN OUT");
    setUser(undefined);
    keycloak?.logout();
  };

  const getCompanyId = () => {
    return user?.companyId;
  };

  const getAccessToken = () => {
    return keycloak?.access_token;
  };

  const getFunctionalModuleTree = () => {
    if (!!functionalModuleTreeList && !!functionalModuleCategory) {
      return functionalModuleTreeList.find(
        (fmt) => fmt.name === functionalModuleCategory
      );
    }
  };

  return (
    <ContextUser.Provider
      value={{
        user,
        setNeedResolveAccessDictionary,
        userFormatterNumber,
        needResolveUserFormatterNumber,
        setNeedResolveUserFormatterNumber,
        readOnlyFinalizerOrApprobator,
        setReadOnlyFinalizerOrApprobator,
        signOut,
        urlApi,
        getAccessToken,
        userCompany,
        setUserCompany,
        functionalModuleCategory,
        getFunctionalModuleTree,
        getCompanyId,
        getRequestHeaders,
        isUserInhovateAdmin,
        isUserInhovateUser,
        isUserCompanyAdmin,
        isUserCompanyUser,
        isDescriptiveRightReadOnly : user?.descriptiveRight === TypeDescriptiveRight_RO,
        setUser
      }}
      {...props}
    />
  );
}

export { UseContextUserProvider, useContextUser };

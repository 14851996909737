import React, { createContext, useContext } from "react";
import { ERROR_DEFAULT } from "../components/assets/const/ErrorCode";

const ContextLog = createContext({});
const useContextLog = () => useContext(ContextLog);
const LOG_LIST_keyLocalStorage = "inhovateLogList";
// MAX LOGS Stored
export const MAX_LOG_ITEMS = 10;

function UseContextLogProvider(props) {

  const setLogList = (list) => {
    try {
    localStorage.setItem(LOG_LIST_keyLocalStorage, JSON.stringify(list));
    }
    catch (e) {
      if (e?.name === "QuotaExceededError" ){
        localStorage.clear();
        // to save the last error 
        // necessary for error management in infinity loader
        if(Array.isArray(list) && list.length > 0){
          const list2 = [list[0]]
          localStorage.setItem(LOG_LIST_keyLocalStorage, JSON.stringify(list2));
        }
      }
    }
  };

  // log item format
  const unifiedLogResponse = (log) => {
    if (!!log.response) return log;

    if (!!log.responseText) {
      try {
        const parsedRespDetail = JSON.parse(log.responseText);
        log.response = parsedRespDetail;
      } catch (e) {
        try {
          const message = log.msg?.message;
          const statusCode = message.substring(message.length - 3);
          log.response = {
            status: parseInt(statusCode),
            developerMessage: log.responseText ?? "msg nd",
          };
          return log;
        } catch (e) {
          log.response = {
            status: "status nd",
            developerMessage: log.responseText ?? "msg nd",
          };
          return log;
        }
      }
    }
  };

  const getLogList = () => {
    try {
      const tt = localStorage.getItem(LOG_LIST_keyLocalStorage);
      const parsedArray = JSON.parse(tt);
      if (Array.isArray(parsedArray)) {
        parsedArray.map((log) => {
          return unifiedLogResponse(log);
        });

        return parsedArray;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  // ADD A LOG
  const addLog = async (type, msg) => {
  
    let log = {
      type: type,
      time: new Date(),
      msg: msg,
      code: msg?.errorCode,
    };

    if (typeof msg === "object") {
      if (!!msg.config?.method) log.method = msg.config.method;
      if (!!msg.config?.url) log.url = msg.config?.url;
      if (!!msg.request?.responseText)
        log.responseText = msg.request.responseText;

      if (!!msg.message) {
        msg = msg.message;
      }
    }
    // important : to avoid QuotaExceededError in local storage
    // -> delete original potentialy fat message
    delete log.msg

    if (!log.code) {
      log.code = ERROR_DEFAULT;
    }

    const logList = getLogList();

    const list = [...logList];
    if (logList.length >= MAX_LOG_ITEMS) {
      list.shift();
    } 
    list.push(log);
    setLogList(list);
  };

  // CLEAR ALL
  const clear = async () => {
    setLogList([]);
  };

  const addErrorLog = (errorMsg, errorCode) => {
    try {
      if (typeof errorMsg !== "object") {
        const err = { message: errorMsg };
        err.errorCode = errorCode;
        addLog("error", err);
      } else {
        if (!!errorCode) {
          errorMsg.errorCode = errorCode;
        }
        addLog("error", errorMsg);
      }
    } catch (e) {
      console.error(e, errorMsg);
    }
  };

  const addWarnLog = (warnMsg) => {
    addLog("warning", warnMsg);
  };

  const addInfoLog = (infoMsg) => {
    addLog("info", infoMsg);
  };

  return (
    <ContextLog.Provider
      value={{
        getLogList,
        addErrorLog,
        addWarnLog,
        addInfoLog,
        clear,
        //findErrorWithListCodes
      }}
      {...props}
    />
  );
}

export { UseContextLogProvider, useContextLog };

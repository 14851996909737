import React, { createContext, useContext, useEffect } from 'react'
import { getBrowserLanguage, i18nWithLanguage, getJJMMAAAAWithLanguage, getHHMMWithLanguage, getJJMMAAAA_HHMMWithLanguage, getMomentumWithLanguage, localeWithoutRegionCode, getMMMdyyyyWithLanguage, getMMMyyWithLanguage, getDayDateAndMonthAndYearWithLanguage, getMonthAndYearWithLanguage, getDddWithLanguage, getYYYYWithLanguage} from '../i18n/utils'
import useStateWithCallback from 'use-state-with-callback';
import { locale } from "devextreme/localization";


const ContextI18n = createContext({})
const useContextI18n = () => useContext(ContextI18n)

function UseContextI18nProvider(props) {

  const [language, setLanguage] = useStateWithCallback(null,
    (lang) => {
      if (!!lang && lang !== sessionStorage.getItem('language')) {
        sessionStorage.setItem('language', lang);
        locale(lang);

      }
    }
  )


  useEffect(() => {
    // initialisation par défaut de la langue avec celle du navigateur

    if (!language) {
      var lang;
      if (!!sessionStorage.getItem('language')) {
        lang = sessionStorage.getItem('language');
      } else {
        lang = getBrowserLanguage()
      }
      locale(lang);
      setLanguage(lang)

    }
  }, [language, setLanguage])

  const i18n = (key, values) => {
    try {
      return i18nWithLanguage(key, values, language)
    } catch (e) {
      console.warn(`i18n ${key}`, values)
      return `${key}[${language}]`
    }
  }

  const getJJMMAAAA = (date) => {
    return getJJMMAAAAWithLanguage(date, language)
  }

  const getMMMdyyyy = (date) => {
    return getMMMdyyyyWithLanguage(date, language)
  }

  // return date as 'jeudi 15 septembre 2022'/ 'Thursday, September 15, 2022'
  const getDayDateAndMonthAndYear = (date) => {
    return getDayDateAndMonthAndYearWithLanguage(date, language)
  }

  const getShortDayName = (date) => {
    return getDddWithLanguage(date, language)
  }

  const getMonthAndYear = (date) => {
    return getMonthAndYearWithLanguage(date, language)
  }

  const getMMMyy = (date) => {
    return getMMMyyWithLanguage(date, language)

  }
  const getHHMM = (date) => {
    return getHHMMWithLanguage(date, language)
  }

  const getJJMMAAAA_HHMM = (date) => {
    return getJJMMAAAA_HHMMWithLanguage(date, language)
  }

  const getYYYY = (date) => {
    return getYYYYWithLanguage(date, language)
  }

  const getMomentum = (date) => { //'morning','afternoon' or 'evening'
    return getMomentumWithLanguage(date, language)
  }

  const getFirstDayOfWeekForLibMoment = () => {
    switch(language){
      case 'fr-FR': return 0; //monday
      case 'en-US': return 6; //sunday
      default: return 0;
    }
  }

  const getFirstDayOfWeekForEchart = () => {
    switch(language){
      case 'fr-FR': return 1; //monday
      case 'en-US': return 0; //sunday
      default: return 0;
    }
  }

  const getLocale = () => {
    if (!!language) {
      return localeWithoutRegionCode(language)
    } else {
      return undefined
    }
  }

  const getDayFirstLetterList = () => {
    switch(language){
      case 'fr-FR': return ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
      case 'en-US': 
      default: return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    }
  }

  return <ContextI18n.Provider value={{
    language, setLanguage,
    i18n,
    getJJMMAAAA,
    getDayDateAndMonthAndYear,
    getShortDayName,
    getMonthAndYear,
    getMMMdyyyy,
    getMMMyy,
    getHHMM,
    getYYYY,
    getJJMMAAAA_HHMM,
    getMomentum,
    getFirstDayOfWeekForLibMoment,
    getFirstDayOfWeekForEchart,
    getDayFirstLetterList,
    getLocale
  }} {...props} />
}

export { UseContextI18nProvider, useContextI18n }
